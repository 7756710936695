<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
            <b-card class="mt-4">
                <div class="clearfix">
                    <h1 class="float-left">{{ user[0].name }}</h1>
                    <div class="float-right">
                        <b-button variant="primary" class="btn btn-sm btn-icon-only rounded-circle"
                                  @click="editBasicData(user)">
                            <i class="simple-icon-pencil"></i>
                        </b-button>
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-3 pl-0">
                        <b-col>
                            <h3>Username</h3>
                            <span>{{ user[0].username }}</span>
                        </b-col>
                    </div>
                    <div class="col-3 pl-0">
                        <b-col>
                            <h3>Email</h3>
                            <span>{{ user[0].email }}</span>
                        </b-col>
                    </div>
                    <div class="col-3 pl-0">
                        <b-col>
                            <h3>Contact Number</h3>
                            <span>{{ user[0].phone }}</span>
                        </b-col>
                    </div>
                    <div class="col-3 pl-0">
                        <b-col>
                            <h3>WhatsApp Number</h3>
                            <span>{{ user[0].whatsapp_number }}</span>
                        </b-col>
                    </div>
                </div>
            </b-card>
        </div>
        </div>

        <div class="row">
            <div class="col-md-4 col-sm-12 col-xs-12 d-flex-md" v-for="(address, index) in addresses" v-bind:key="index">
            <div class="card card-pricing border-0 text-center mb-4">
                <div class="card-header bg-transparent clearfix">
                    <div class="mt-3 float-left">
                        <h2>Address</h2>
                    </div>
                    <div class="mt-3 float-right">
                        <b-button variant="primary" class="btn btn-sm btn-icon-only rounded-circle" @click="editItem(address)">
                            <i class="simple-icon-pencil"></i>
                        </b-button>
                        <b-button variant="danger" class="btn btn-sm btn-icon-only rounded-circle"
                                  @click="deleteItem(index)">
                            <i class="simple-icon-trash"></i>
                        </b-button>
                    </div>
                </div>

                <div class="card-body px-lg-7">
                    <strong><span class="text-muted">{{ address.name }}</span></strong><br>
                    <span class="text-muted">{{ address.address_line_1 }}</span><br>
                    <span class="text-muted">{{ address.address_line_2 }}</span><br>
                    <span class="text-muted">{{address.locality}} </span><br>
                    <span class="text-muted">{{address.district}}, {{ address.state }}</span><br>
                    <span class="text-muted">{{ address.country }}</span><br>
                    <span class="text-muted">{{ address.pin_code }}</span>

                </div>
            </div>

        </div>
        </div>

        <button id="fixedButton"
                type="button"
                class="btn base-button btn-icon-only rounded-circle btn-default btn-xl"
                @click="openModal">
            <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
            <span class="fixed-text">New Address</span>
        </button>
        <b-modal title="Address" id="modal" size="lg" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-6">
                            <validated-input name="House Name / Flat No" label="House Name / Flat No"
                                             v-model="model.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Address line 1" label="Address line 1" v-model="model.address_line_1"
                                             :rules="rules.address_line_1"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Address line 2" label="Address line 2" v-model="model.address_line_2"
                                             :rules="rules.address_line_2"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Locality" label="Locality" v-model="model.locality"
                                             :rules="rules.locality"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="District" label="District" v-model="model.district"
                                             :rules="rules.district"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="State" label="State" v-model="model.state"
                                             :rules="rules.state"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Country" label="Country" v-model="model.country"
                                             :rules="rules.country"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Pin code" label="Pin code" v-model="model.pin_code"
                                             :rules="rules.pin_code" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-modal title="Details" id="detailsModal" size="lg" no-close-on-esc hide-footer @hidden="detailsModel={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(basicUpdate)">
                    <div class="row">
                        <div class="col-md-12">
                            <validated-input name="Name" label="Name" v-model="detailsModel.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Email" label="Email" v-model="detailsModel.email" :rules="rules.email"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Phone" label="Phone Number" v-model="detailsModel.phone"
                                             :rules="rules.phone" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Whatsapp" label="WhatsApp Number" v-model="detailsModel.whatsapp_number"
                                             :rules="rules.whatsapp_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-12">
                            <b-button @click="basicUpdate" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'UserDetails',
    components: {},
    data () {
        return {
            user: [],
            addresses: [],
            detailsModel: {
                id: '',
                name: '',
                email: '',
                phone: '',
                whatsapp_number: ''
            },
            model: {
                // address_type: '',
                user_id: this.$route.params.id,
                name: '',
                username: '',
                address_line_1: '',
                address_line_2: '',
                locality: '',
                district: '',
                state: '',
                country: '',
                pin_code: ''
            },
            rules: {
                name: {
                    required: true
                },
                // address_type: {
                //     required: true
                // },
                address_line_1: {
                    required: true
                },
                district: {
                    required: true
                },
                state: {
                    required: true
                },
                country: {
                    required: true
                },
                pin_code: {
                    required: true
                }
            }
        };
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },

        setData (response) {
            console.log('this.res', response);
            if (response.data.User) {
                this.addresses = response.data.Addresses;
                console.log('this.address', this.addresses);
                this.user = response.data.User;
                console.log('this.user', this.user);
            }
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.userAddress.update;
            } else {
                url = urls.userAddress.create;
            }

            const response = await axios.form(url, this.model);
            console.log('add response', response);
            this.addresses.push(response.data.obj);
            this.$bvModal.hide('modal');
        },

        async loadData () {
            const response = await axios.post(urls.userAddress.details + '?id=' + this.$route.params.id);
            console.log('response:>', response.data);
            this.setData(response);
        },

        async editItem (item) {
            this.model = {
                id: item.id,
                user_id: this.$route.params.id,
                name: item.name,
                address_line_1: item.address_line_1,
                address_line_2: item.address_line_2,
                locality: item.locality,
                district: item.district,
                state: item.state,
                country: item.country,
                pin_code: item.pin_code
            };
            this.openModal();
        },

        async deleteItem (index) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: this.addresses[index].id };
                const response = await axios.form(urls.userAddress.delete, params);
                console.log('delete response', response);
                this.setData(response);
            }
        },

        // basic details //
        openBasicModal () {
            this.$bvModal.show('detailsModal');
        },

        async basicUpdate () {
            let url;
            if (this.detailsModel.id) {
                url = urls.users.update;
            } else {
                url = urls.users.create;
            }

            const response = await axios.form(url, this.detailsModel);
            // this.setBasicData(response);
            this.user.push(response.data.obj);
            this.$bvModal.hide('detailsModal');
        },

        async editBasicData (item) {
            this.detailsModel = {
                id: this.$route.params.id,
                name: item[0].name,
                email: item[0].email,
                phone: item[0].phone,
                whatsapp_number: item[0].whatsapp_number
            };

            this.openBasicModal();
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }

    }
};
</script>

<style>
#fixedButton {
    position: fixed;
    bottom: 5%;
    right: 2%;
    width: 50px;
    height: 50px;
    font-size: 33px;
    background: #2c7701;
}

#fixedButton .btn-inner--icon i:not(.fas):not(.fab) {
    position: relative;
    top: 4px;
}

#fixedButton .fixed-text {
    display: none;
    position: absolute;
    right: 35px;
    top: 12px;
    left: -120px;
    background: #2c7701;
    border-radius: 10px;
    padding: 2px 6px;
    font-size: 13px;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
}

#fixedButton:hover .fixed-text {
    display: block;
    transform: scaleX(1);
    transform-origin: 100% 50%;

}

#fixedButton:focus,
#fixedButton:focus .fixed-text {
    box-shadow: none;
    background: #2c7701;

}

</style>
